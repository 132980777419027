.bl-textwithquote {
    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            //gap:rem-calc(110);
            gap:var(--imagesgap);
        }
    }
    @include breakpoint(medium down) {
        .colquote {
            margin-top:rem-calc(130);
        }
    }
    @include breakpoint(medium only) {
        .colquote {
            margin-left:10vw;
        }
    }
    .quote {
        @include typo-maintitle;
        color:$color1;
        margin-top:rem-calc(110);
    }
    .button {
        margin-top:2.5em;
    }
}