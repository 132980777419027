$black: #000;
$white: #fff;

$color1:#948198;
$textcolor:#5a5a5a;
$green:#73BF91;

body {
    --imagesgap: #{rem-calc(20)};
    //--gap1:2rem; 

    /*
    --borderwidth:2px;
    @media (min-device-pixel-ratio: 2) {
        --borderwidth:1.5px;
    }
    */
}