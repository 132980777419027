.bl-textwithimage {
    &.imgvalignbottom .colimage {
        align-self: end;
    }
    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap:rem-calc(110);
        }
    }
    @include breakpoint(medium down) {
        .colimage {
            margin-top:rem-calc(130);
        }
    }
    /*
    @include breakpoint(medium only) {
        &.mediumimgright {
            .colimage {
                margin-left:auto;
            }
        }
    }
    */
    .button {
        margin-top:2.5em;
        @include breakpoint(large) {
            margin-bottom: 3em;
        }
    }

    @include breakpoint(large) {
        h2 {
            max-width:46%;
        }
    }
}

.bl-textwithimage {
    &.qf-person {
        .colimage img {
            max-width:rem-calc(540);
            @include breakpoint(medium only) {
                max-width:none;
                width:calc(100% - 15rem);
            }
        }
    }

    &.qf-massage {
        @include breakpoint(large) {
            .cols {
                grid-template-columns: 63fr 50fr;
            }
        }
        @include breakpoint(large only) {
            .cols {
                gap:rem-calc(50);
            }
        }
        .colimage img {
            @include breakpoint(medium only) {
                margin-left: auto;
                max-width:none;
                width:calc(100% - 15rem);
            }
        }
    }
}