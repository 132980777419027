.buttonbar {

}
.button {
  display: block;
  width: fit-content;
  @include font-size-pt(20, 22);
  font-weight: 700;
  padding:0.5em 1em 0.6em 1em;
  background-color: #73BF91;
  color:white;
  border-radius: 5px;
  transition:background-color 0.4s;

  &:hover, &:active, &:focus {
    background-color: #6AAF85;
  }
}
