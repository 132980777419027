.bl-terminv {
    @include breakpoint(medium) {
        .cols {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:rem-calc(30);
        }
    }
    @include breakpoint(medium down) {
        .colwidget {
            margin-top:rem-calc(50);
        }
    }
    .coltext {
        color:white;
    }
    .emrinfo {
        @include font-size-pt(16, 22);
        margin-top:2em;
    }
    .widget {
        box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.5);
        background-color: white;
        border-radius: 10px;
        padding:1em;

        h2 {
            color:$green;
        }

        iframe {
            border:none;
            width:100%;
            height:880px;
            @include breakpoint(small down) {
                height:1050px;
            }
        }
    }
}