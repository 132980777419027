.bl-quote {
    @include rem-calc-sl(padding-top, 190, math.div(110, 190));
    @include rem-calc-sl(padding-bottom, 100, math.div(50, 100));

    @include breakpoint(medium down) {
        @include rem-calc-sl(padding-top, 100, math.div(50, 100));
        @include rem-calc-sl(padding-bottom, 100, math.div(50, 100));
    }

    @include breakpoint(large) {
        margin-left:rem-calc(150);
    }
    @include breakpoint(medium only) {
        margin-left:10vw;
        //max-width:rem-calc(480);
        margin-right:20vw;
    }

    max-width:rem-calc(780);

    .quote {
        @include typo-maintitle;
        color:white;
    }
    .author {
        margin-top:1.5em;
        color:white;
    }
}

.bl-quote {
    @include breakpoint(large) {
        @media (max-width:1499px) {
            padding-top:rem-calc(60);
            padding-bottom:rem-calc(60);
        }
    }
}
