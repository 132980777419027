//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    margin-top:2em;
}


//Spezifische Abstaende
.bl-sitetitle {
    @include rem-calc-sl(margin-top, 100, math.div(35, 100));
}
.bl-sitetitle + .bl-textwithimage {
    @include rem-calc-sl(margin-top, 150, math.div(60, 150));
    @include breakpoint(medium only) {
        margin-top:rem-calc(60);
    }
}

.bl-images2, .bl-images3 {
    margin-top:var(--imagesgap);
}

/*
.ws1 {
    .bl-richtext + .bl-slider {
        margin-top:2em;
    }
    .bl-slider + .bl-linkboxes {
        margin-top:2em;
    }
}
*/

/*
.cblock + .bl-button {
    margin-top: 8em;
    margin-bottom: 8em;
}

.bl-textwithimage + .bl-textwithimage {
    margin-top:20em;
    @include breakpoint(medium down) {
        margin-top:12em;
    }
}
*/