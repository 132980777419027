.bl-images2 {
    @include breakpoint(medium) {
        display:grid;
        grid-template-columns: 2fr 1fr;
        gap:var(--imagesgap);
    }

    @include breakpoint(small down) {
        img + img {
            margin-top:var(--imagesgap);
        }
    }
}