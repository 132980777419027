footer {
  background-color: #514752;
  color:white;

  @include font-size-pt(18, 22);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:1.3;

  padding: rem-calc(40 0);
  a {
    color: $green;
    &:hover {
    }
  }

  @include breakpoint(large) {
    .cols {
      display: flex;
      gap:3em;
    }
  }

  @include breakpoint(medium down) {
    .col2, .col3 {
      margin-top:1.3em;
    }
  }

  .ctrcopy {
    margin-top:3em;
    border-top:1px solid white;
    padding-top:1.6em;

    @include breakpoint(large) {
      display:flex;
      gap:1em;
    }
  }
  .menu {
    display:flex;
    gap:1em;
    @include breakpoint(medium down) {
      margin-top:1em;
    }
    @media(max-width:450px) {
      justify-content: space-between;
    }
  }
}

