header {
  border-top:rem-calc(30) solid $color1;
  
  @include rem-calc-sl(padding, 50 0, math.div(20, 50));

  .logo {
    @include rem-calc-sl(width, 300, math.div(180, 300));
  }
  
}
