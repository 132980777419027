//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(20, 26);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:1.4;
  color:$textcolor;
}

body {
  font-family:"europa", sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale
}
a {
  text-decoration: none;
  color:$green;
}
a:focus {
  outline: none;
}

@mixin typo-maintitle {
  @include font-size-pt(30, 50);
  @include breakpoint(xlarge) { //Ausnahme Starttitel und quote
    font-size:60px;
  }
  @include breakpoint(medium only) { //Ausnahme wegen quote
    font-size:42px;
  }
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.2;
}

@mixin typo-subtitle {
  @include font-size-pt(22, 30);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.3;
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}

h1, h2, h3, h4 {
  margin:0 0 0.75em 0;
}
h1 {
  @include typo-maintitle;
  color:$color1;
}
h2 {
  @include typo-subtitle;
}

