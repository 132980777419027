.bl-images3 {
    @include breakpoint(medium) {
        .cols {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:var(--imagesgap);
        }
        .img1 {
            margin-top: rem-calc(200);
            max-width:rem-calc(630);
        }
        .img2 {
            //max-width:rem-calc(430);
            width:64%;
        }
        .img1, .img2 {
            margin-left: auto;
        }
    }
    img + img {
        margin-top:var(--imagesgap);
    }

    @include breakpoint(medium only) {
        .img2 {
            max-width: none;
            width:calc(100% - 7rem);
        }
    }
    @include breakpoint(small down) {
        img {
            margin-top:var(--imagesgap);
        }
        .img1 {
            margin-top: 0;
        }
    }
}